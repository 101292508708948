import React, { useEffect, useState } from 'react';
import { changeLocale } from 'gatsby-plugin-intl';

import FRFlag from '../../images/FR_flag.png';
import GBFlag from '../../images/GB_flag.png';
import PLFlag from '../../images/PL_flag.png';

import './LanguageSelect.scss';


const languagesData = {
  pl: { langCode: 'pl', flag: PLFlag },
  en: { langCode: 'en', flag: GBFlag },
  fr: { langCode: 'fr', flag: FRFlag },
};

const LanguageSelect = () => {
  const [windowObject, setWindowObject] = useState({});

  const pathname = windowObject.location?.pathname;
  const currentLanguage = pathname && pathname.replace('/', '').replace('/', '');
  const allowedLanguages = Object.keys(languagesData);

  useEffect(() => {
    setWindowObject(window);
  }, []);

  const handleOnclick = lang => {
    if(currentLanguage) {
      if(currentLanguage !== lang) changeLocale(languagesData[lang].langCode);
    } else {
      changeLocale(languagesData[lang].langCode);
    }
  };

  return (
    <div className="LanguageSelect">
      {allowedLanguages.map(lang => (
        <div
          className="LanguageFlag"
          key={languagesData[lang].langCode}
          onClick={() => handleOnclick(lang)}
        >
          <img src={languagesData[lang].flag} alt={`${languagesData[lang].flag} flag`} />
        </div>
      ))}
    </div>
  );
};

export default LanguageSelect;
