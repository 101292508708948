import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import MainLogo from '../../images/logo_main.png';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

import HamburgerMenu from './HamburgerMenu';

import './Header.scss';


const Header = () => {
  const { formatMessage } = useIntl();
  // const [scrollTop, setScrollTop] = useState(0);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    function onScroll() {
      const currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if(currentPosition > 150) {
        // downscroll code
        setIsScrolledDown(true);
      } else if(currentPosition <= 150) {
        setIsScrolledDown(false);
      }
      // setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return window.addEventListener('scroll', onScroll);
  }, []);

  const navLinks = [
    { label: formatMessage({ id: 'O firmie' }), to: 'about' },
    { label: formatMessage({ id: 'Zespół' }), to: 'team' },
    { label: formatMessage({ id: 'Usługi' }), to: 'services' },
    { label: formatMessage({ id: 'Kontakt' }), to: 'contact' },
  ];

  const handleRedirect = id => {
    const section = document.getElementById(id);

    window.scrollTo({
      top: section.offsetTop - 75,
      left: 0,
      behavior: 'smooth',
    });
  };

  const renderNavigationLinks = () => (
    navLinks.map(link => (
      <div className="MainNavigation_Link" onClick={() => handleRedirect(link.to)} key={link.to} to={link.to} >
        {link.label}
      </div>
    ))
  );

  return (
    <header className={classnames('MainHeader', { NonTransparent: isScrolledDown })}>
      <div className="MainHeader_ContentWrapper">
        <Link to="/">
          <div className="LogoContainer">
            <img src={MainLogo} alt="Logo Nova Green" />
          </div>
        </Link>

        <div className="vertical-center">
          <LanguageSelect />

          <nav className="MainNavigation">
            {renderNavigationLinks()}
          </nav>

          <HamburgerMenu renderNavigationLinks={renderNavigationLinks} />
        </div>
      </div>
    </header>
  );
};

export default Header;
